"use client";
import { useState, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import Link from "next/link";
import Image from "next/image";

const navigation = [
  { name: "Home", href: "/" },
  {
    name: "Products",
    href: null,
    submenu: [
      {
        name: "TOUCHKEY FINGERPRINT READER",
        href: "/touchkey-fingerprint-reader",
      },
      { name: "BIOHANDLE", href: "/biohandle" },
    ],
  },
  {
    name: "Downloads and Links",
    href: null,
    submenu: [
      { name: "DOWNLOAD BROCHURE", href: "/downloads/Touchkey-2024-DLR.pdf" },
      {
        name: "DOWNLOAD MANUAL",
        href: "/downloads/Touchkey-download-manual.pdf",
      },
      {
        name: "AURUS LOCKING SOLUTIONS",
        href: "https://auruslocks.com/product/electric-locks-and-accessories/",
      },
      { name: "WATCH VIDEOS", href: "/videos" },
    ],
  },
  { name: "News and Events", href: "/news-and-events" },
  { name: "Contact Us", href: "/#footer" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = () => {
  const [openSubmenu, setOpenSubmenu] = useState(null);

  const handleSubmenuToggle = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".submenu")) {
      setOpenSubmenu(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLinkClick = (close) => {
    setOpenSubmenu(null);
    close(); // Close the Disclosure panel
  };

  return (
    <header className="sticky top-0 z-[999] w-full transition-colors duration-300 font-bold tracking-wider bg-black/20">
      <Disclosure as="nav" className="bg-white py-4">
        {({ open, close }) => (
          <>
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
              <div className="relative flex items-center justify-between h-16">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 uppercase hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex-shrink-0 ml-8 md:pl-0">
                    <Link href="/">
                      <Image
                        src="/images/logo.png"
                        alt="Touchkey logo"
                        width={340}
                        height={45}
                        className=""
                      />
                    </Link>
                  </div>
                  <div className="hidden sm:block sm:ml-6 flex-1">
                    <div className="flex space-x-4 justify-end">
                      {navigation.map((item, index) =>
                        item.submenu ? (
                          <div key={item.name} className="relative submenu">
                            <button
                              onClick={() => handleSubmenuToggle(index)}
                              className="text-black px-0 py-2 rounded-md uppercase text-[1rem] font-medium flex items-center"
                            >
                              {item.name}
                              <ChevronDownIcon
                                className={`ml-2 h-5 w-5 transition-transform  ${
                                  openSubmenu === index
                                    ? "transform rotate-180"
                                    : ""
                                }`}
                              />
                            </button>
                            {openSubmenu === index && (
                              <div className="absolute z-10 bg-white shadow-lg rounded-md py-2 mt-1 w-48">
                                {item.submenu.map((subitem) => (
                                  <Link
                                    key={subitem.name}
                                    href={subitem.href}
                                    className="block px-4 py-2 uppercase text-[1rem] text-gray-700 hover:bg-gray-100"
                                    onClick={() => handleLinkClick(close)}
                                  >
                                    {subitem.name}
                                  </Link>
                                ))}
                              </div>
                            )}
                          </div>
                        ) : (
                          <Link
                            key={item.name}
                            href={item.href}
                            className="text-black px-3 py-2 rounded-md uppercase text-[1rem] font-medium"
                            onClick={() => handleLinkClick(close)}
                          >
                            {item.name}
                          </Link>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item, index) =>
                  item.submenu ? (
                    <Disclosure key={item.name} as="div">
                      {({ open: submenuOpen }) => (
                        <>
                          <Disclosure.Button
                            as="button"
                            className="text-black px-3 py-2 rounded-md uppercase text-[1rem] font-medium w-full flex items-center justify-between"
                            onClick={() => handleSubmenuToggle(index)}
                          >
                            {item.name}
                            <ChevronDownIcon
                              className={`h-5 w-5 transition-transform ${
                                openSubmenu === index
                                  ? "transform rotate-180"
                                  : ""
                              }`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel
                            className={`space-y-1 ${
                              submenuOpen ? "block" : "hidden"
                            }`}
                          >
                            {item.submenu.map((subitem) => (
                              <Link
                                key={subitem.name}
                                href={subitem.href}
                                className="block px-4 py-2 uppercase text-[1rem] text-gray-7002 hover:bg-gray-100 ml-4 text-blue-600"
                                onClick={() => handleLinkClick(close)}
                              >
                                {subitem.name}
                              </Link>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ) : (
                    <Link
                      key={item.name}
                      href={item.href}
                      className="block text-black px-3 py-2 rounded-md uppercase text-[1rem] font-medium"
                      onClick={() => handleLinkClick(close)}
                    >
                      {item.name}
                    </Link>
                  )
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </header>
  );
};

export default Header;
